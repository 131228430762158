<template>
	<div>
		<div>
			<ul class="note flex_r f_jc_a f_ai_c pl20 pt20 pr20 pb20 br_1_eee bg_default">
				<li>
					<span>累计发送短信</span>
					<span>123条</span>
				</li>
				<li>
					<span>本月发送短信</span>
					<span>123条</span>
				</li>
				<li>
					<span>本周发送短信</span>
					<span>123条</span>
				</li>
				<li>
					<span>今日发送短信</span>
					<span>123条</span>
				</li>
				<div class="setting">
					<div>短信剩余</div>
					<div class="pt6">
						<span class="cr_ff0 fs20">123条</span>
						<el-link type="danger" class="ml10" @click="$refs.dialogRef.handleOpen()">立即充值>></el-link>
					</div>
					<div class="pt6 pb6">
						<span>(剩余0条时提醒我)</span>
						<el-link type="primary" class="ml10">设置</el-link>
					</div>
					<div class="flex_r f_ai_c">
						<el-input></el-input>
						<el-tag class="ml4 mr4" type="danger">确认</el-tag>
						<el-tag>取消</el-tag>
					</div>
				</div>
			</ul>
		</div>
		<e-table :tableCols="noteTableConf" :dataOrigin="dataOrigin" :showOperation="true">
		</e-table>
		<e-dialog width="1000" title="短信充值" ref="dialogRef" :showConfirm="false">
			<ul class="check_note flex_r flex_wrap">
				<li :class="current === 1 ? 'active' : ''" @click="current = 1" class="flex_c">
					<span>2千条套餐包</span>
					<span class="pt10 pb10 cr_999 td_line">原价￥120.00</span>
					<span><span class="fs18 fw_b">￥110.00</span>(0.055元/条)</span>
					<i v-if="current === 1" class="el-icon-check" style=""></i>
				</li>
				<li :class="current === 2 ? 'active' : ''" @click="current = 2" class="flex_c">
					<span>2千条套餐包</span>
					<span class="pt10 pb10 cr_999 td_line">原价￥120.00</span>
					<span><span class="fs18 fw_b">￥110.00</span>(0.055元/条)</span>
					<i v-if="current === 2" class="el-icon-check" style=""></i>
				</li>
			</ul>
			<ul class="check_note flex_r flex_wrap">
				<li class="flex_r active">
					<img width="50" height="50" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/icon-zfb%402x.png" alt="" srcset="">
					<div class="ml10 flex_c f_jc_a">
						<span>支付宝</span>
						<span class="cr_999 fs12">数亿用户都在用，安全可托付</span>
					</div>
					<i class="el-icon-check" style=""></i>
				</li>
			</ul>
			<el-divider class="mt20 mb30"></el-divider>
			<span>
				应付金额：
				<span class="fs24 fw_b cr_ff0">￥2000.00</span>
			</span>
			<div class="mt30">
				<el-button size="large" type="primary">立即支付</el-button>
			</div>
		</e-dialog>
	</div>
</template>

<script>
import {noteTableConf} from '@data/mine'
export default {
	name: 'Note',
	data() {
		return {
			noteTableConf,
			dataOrigin: {
				data: [
				]
			},
			current: 1
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>

<style lang="scss" scoped>
.note li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 20%;
	& > span:nth-child(2) {
		font-size: 24px;
		font-weight: bold;
		padding-top: 20px;
	}
}
.setting {
	border-left: 1px solid #eee;
	padding-left: 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	// justify-content: space-around;
}
.el-icon-check {
    position: absolute;
    right: 2px;
    bottom: -1px;
    color: #fff;
    font-size: 20px;
}
.check_note li {
	width: 30%;
	position: relative;
	border: 1px solid #eee;
	padding: 20px;
	margin: 10px;
}
.check_note li.active:before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 30px 38px;
    border-color: transparent transparent #ff474a transparent;
}
</style>